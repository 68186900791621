.appIcon {
	width:128px;
	height:128px;
	background: url("/img/icon256.png") no-repeat center center;
	background-size:cover;
	margin-right:5%;
}

$redColor: #a247d9;
$redBorderColor: #53137a;
$redTextColor: white;

img, video {
	max-width: 100%;
}
.picture-map {
	display: flex;
	flex-direction: column;	
}

figure {
	text-align: center;
}
img {
	margin: auto;
} 

.jumbotron {
  background: url('/img/scratch.jpg') no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  top:0;
  left:0;
  .fs-1 {
	  color: $redTextColor;
	  font-family:'Helvetica Neue Bold', 'Helvetica Neue',sans-serif;
	  font-weight: bolder;
	  em {
		  font-family: serif;
	  }
  }
  small {
	  font-family: 'Helvetic Neue UltraLight', 'Helvetica Neue', sans-serif;
	  font-weight: 100;
	  color: white;
  }
  .btn-primary {
	  background-color: $redColor;
	  border-color: $redBorderColor;
  }
  .btn-primary:active {
      border-color: $redColor;
	  background-color: $redBorderColor;
	  color: black;
  }
  .nav {
	  .nav-link {
		  color: $redTextColor;
		  &:hover {
			 color: black;
		  };
	  }
	  .nav-link.active {
		  background-color: $redColor;
		  border: 1px solid $redBorderColor;
	  }
  }
}

// disable hoverSee Safari-Plugin to not make images pop up when hovering over images
.hoverSeeViewer_normal {
	display:none !important;
}

.keyboard-types, figure.center-block {
	img {
		margin-left: auto;
		margin-right: auto;
	}
}
